<template>
  <div class="payment__container container is-max-desktop ">
    <h1 class="payment__title">Біздің курс жайлы</h1>
    <!-- width="{video_width}" 
    height="{video_height}" 
    title="Титул"  -->
    <!-- <iframe 
      src="https://player.vimeo.com/video/504288961" 
      width="327px"
      height="auto"
      frameborder="0" 
      webkitallowfullscreen 
      mozallowfullscreen 
      allowfullscreen
    ></iframe> -->
    <div class="video_wrapper">
      <iframe 
        src="https://player.vimeo.com/video/504288961?app_id=122963" 
        width="320" 
        height="180"
        frameborder="0" 
        allow="autoplay; fullscreen; picture-in-picture" 
        allowfullscreen>
      </iframe>
    </div>
    <a 
      href="https://kaspi.kz/pay/_gate?action=service_with_subservice&service_id=3025&region_id=18&subservice_id=11947&started_from=global_search"
      class="kaspi-btn"
    >
      <img src="@/assets/logo-kaspi.png" alt="" />
      <span class="kaspi-btn__text">KASPi арқылы төлем жасау</span>
    </a>
    <a 
      v-if="canShare"
      class="share-btn"
      @click="handleShareClick"
    >
      <img src="@/assets/share-icon.svg" alt="" />
      <span class="share-btn__text">Ата-анама жіберу</span>
    </a>
    <p class="payment__sub-text">"Сатып алу" батырмасын басып, сіз 
      <br/>
      <a 
        href="https://app.joo.kz/static/public_offer_rus.pdf"
        target="_blank"  
      >Жария офертамен</a> келісесіз
    </p>
    
    
    <!-- https://vimeo.com/user126526416/review/504288961/7a00f6b7ca -->
    <!-- https://kaspi.kz/pay/_gate?action=service_with_subservice&service_id=3025&region_id=18&subservice_id=11947&started_from=global_search -->

  </div>
</template>

<script>
export default {
  data(){
    return {
      canShare: navigator.share && true,
    }
  },
  methods: {
    handleShareClick(){
      navigator.share({
        title: 'Joo.kz - гранттар, мамандықтар, ЖОО',
        text: "Төлем жасау үшін сілтеме бойынша өтіңіз",
        url: window.location.href,
        // title: A USVString representing the title to be shared.
        // files: A "FrozenArray" representing the array of files to be shared.
      })
    },
  },
}
</script>

<style lang='scss'>
.payment__title{
  font-family: Suisse Intl, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 36px;
  text-align: center;
  color: #151D24;
  margin-bottom: 24px;
}
.payment__container {
  max-width: 375px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
a.kaspi-btn,a.kaspi-btn:hover,a.kaspi-btn:active,a.kaspi-btn:visited,a.kaspi-btn:link{
  margin-top: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 12px 27px;
  border-radius: 4px;
  width: 100%;
  height: 56px;
  background: #EF4735;
  img {
    width: 32px;
    height: auto;
    padding-right: 10px;
  }
  
  .kaspi-btn__logo{
    height: 32px;
  }

  .kaspi-btn__text{
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    align-items: center;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #FFFFFF;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 10px 0px;
  }
}
a.share-btn,a.share-btn:hover,a.share-btn:active,a.share-btn:visited,a.share-btn:link{
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 12px 27px;
  border-radius: 4px;
  width: 100%;
  height: 56px;
  background: #F9F9F9;
  border: 1px solid #E8E6E5;
  img {
    width: 32px;
    height: auto;
    padding-right: 10px;
  }
  
  .share-btn__logo{
    height: 32px;
  }

  .share-btn__text{
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    align-items: center;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: black;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 10px 0px;
  }
}
.payment__sub-text{
  margin-top: 16px;
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  // display: flex;
  // align-items: center;
  text-align: center;
  letter-spacing: 0.02em;
  color: #9E9B98;
  a, 
  a:hover,
  a:active,
  a:visited,
  a:link{
    text-decoration: underline;
    color: black;
  }
}
.video_wrapper {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%; /* 16:9, for an aspect ratio of 1:1 change to this value to 100% */ 
  iframe{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
}
</style>